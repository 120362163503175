<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card class="minHeight">
          <div class="d-block d-sm-flex justify-content-sm-end">
            <div>
              <Popover
                  trigger="manual"
                  placement="left"
                  :content="$t('clickAddCategory')"
                  popper-class="font-weight-bold shadow-popover"
                  v-model="isPopoverVisible"
              >
                <base-button
                  slot="reference"
                  v-if="isAdmin"
                  type="primary"
                  class="mb-4"
                  @click="$router.push({ name: 'newCategory' })"
                  >{{ $tc("new") }}</base-button
                >
              </Popover>
            </div>
          </div>
          <base-table
            v-if="!isSpinnerShow"
            :data="categories"
            :columns="categoriesColumns"
            :columnsSize="columnsSize"
            @clickRow="goToDetailView"
          >
            <template slot="columns">
              <th scope="col">{{ $t("name") }}</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.code }}</td>
            </template>
          </base-table>
          <base-table
              v-else-if="categories.length === 0"
              :data="[1]"
              class="table-responsive-md"
          >
            <template slot="columns">
              <th scope="col">{{ $t("name") }}</th>
            </template>
            <template>
              <td class="col"><Spinner parentClasses="justify-content-center"></Spinner></td>
            </template>
          </base-table>
          <template slot="footer">
            <pagination
              v-if="categories.length > 0"
              @turnPage="getItems"
              element="category"
              :perPage="pagination.perPage"
              :lastPage="pagination.lastPage"
              :totalItems="pagination.totalItems"
              :isChanging="pagination.isChanging"
              :currentPage="currentPage"
            ></pagination>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, BaseTable, Pagination, Spinner } from "@/components/index";
import { mapState } from "vuex";
import { Popover } from "element-ui";

export default {
  name: "CategoryList",
  data() {
    return {
      route: "/store-categories",
      pagination: {
        perPage: 10,
        lastPage: 1,
        totalItems: null,
        isChanging: false
      },
      categories: [],
      categoriesColumns: ["name"],
      columnsSize: [null],
      isSpinnerShow: true,
      isPopoverVisible: false,
      currentPage: 1
    };
  },
  computed: {
    ...mapState(["isAdmin", "defaultTitle"]),
  },
  methods: {
    async getItems(page = 1) {
      this.pagination.isChanging = true;
      this.currentPage = page;
      const route = `${this.route}?page=${this.currentPage}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.store_categories;
        this.categories = data.data;
        this.pagination.perPage = data.per_page;
        this.pagination.lastPage = data.last_page;
        this.pagination.totalItems = data.total;
        this.isSpinnerShow = false;
        this.isPopoverVisible = this.categories.length === 0;
      }
      this.pagination.isChanging = false;
    },
    goToDetailView(itemId) {
      this.$router.push({ name: "manageCategory", params: { id: itemId } });
    },
  },
  created() {
    if (!this.isAdmin) this.$router.push("/dashboard");
  },
  mounted() {
    this.getItems();
  },
  metaInfo() {
    return { title: `${this.$tc('category', 0)} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseTable,
    Pagination,
    Spinner,
    Popover,
  },
};
</script>

<style>
.minHeight {
  min-height: 90vh !important;
}
.shadow-popover {
  box-shadow: 0 2px 12px 0 rgb(0, 0, 0, 50%);
}
</style>
